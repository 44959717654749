<script>
import {defineComponent} from 'vue'
import Table from "@/components/tables/table.vue";
import CreateClientModal from '@/views/pages/accounts/counterparties/modals/client_create.vue'
import PaymentExcelUpload from "@/views/pages/accounts/customers/components/paymentExcelUpload.vue";

export default defineComponent({
  name: "CounterpartiesList",
  components: {
    Table,
    CreateClientModal,
    PaymentExcelUpload
  },
  data() {
    return {
      name: 'Customers List',
      url: '/customer/list/',
      headers: [
        {
          field: 'name',
          label: 'name',
          align: 'center'
        },
        {
          field: 'total_reserved_price',
          label: 'Total reserved price',
          align: 'center',
          searchable: false
        },
        {
          field: 'total_issued_price',
          label: 'Total issued price',
          align: 'center',
          searchable: false
        },
        {
          field: 'total_payment_price',
          label: 'Total payment price',
          align: 'center',
          searchable: false
        },
        {
          field: 'contracts',
          label: 'Contracts',
          align: 'center',
          searchable: false
        },
        {
          field: 'assigned_managers',
          label: 'Responsible managers',
          align: 'center',
        },
      ]
    }
  }
})
</script>

<template>
  <CreateClientModal @client-created="$refs.table.getData()"/>
  <div class="mb-4">
    <Table ref="table"
           :searchable="true" :selectable="true"
           :name="name" :url="url" :headers="headers">
      <template v-slot:name="{row: counterparty}">
        <router-link :to="{name: 'customer_profile', params: {slug: counterparty.slug}}">
          {{ counterparty.name }}
        </router-link>
      </template>
      <template v-slot:total_reserved_price="{row:counterparty}">
        <i class="bx bx-dollar"></i>
        {{ (parseFloat(counterparty.total_reserved_price || 0)).toLocaleString() }}
      </template>
      <template v-slot:total_issued_price="{row:counterparty}">
        <i class="bx bx-dollar"></i>
        {{ (parseFloat(counterparty.total_issued_price || 0)).toLocaleString() }}
      </template>
      <template v-slot:total_payment_price="{row:counterparty}">
        <i class="bx bx-dollar"></i>
        {{ (parseFloat(counterparty.total_payment_price || 0)).toLocaleString() }}
      </template>
      <template v-slot:contracts="{row: counterparty}">
        <b-button variant="light" v-for="contract in counterparty.contracts"
                  :key="`contract_${contract.slug}`" class="mx-2">
          {{ contract.name }}
        </b-button>
        <span v-if="counterparty.contracts.length <= 0">-</span>
      </template>

      <template v-slot:assigned_managers="{row: counterparty}">
        <div class="d-flex gap-2 flex-wrap mx-auto" style="max-width: 200px">
          <router-link v-for="manager in counterparty.assigned_managers" :key="`${counterparty.id}_manager_${manager.id}`"
                       :to="{name: 'user_profile', params: {slug: manager.slug}}"
          >
            <span class="badge cursor-pointer"
                  :class="{
              'bg-primary': manager.username.toLowerCase().includes(($route.query.assigned_managers || '/*/').toLowerCase()),
              'badge-soft-primary': !manager.username.toLowerCase().includes(($route.query.assigned_managers || '/*/').toLowerCase())
                  }"
            >{{ manager.username }}</span>
          </router-link>
        </div>
      </template>

      <template #top-right>
        <div class="d-flex gap-3">
          <PaymentExcelUpload/>
          <a class="btn btn-success" href="#CreateClientModal" data-bs-toggle="modal"><i
              class="ri-add-line align-bottom me-1"></i>
            Create Customer</a>
        </div>
      </template>
    </Table>
  </div>
</template>

<style scoped>

</style>